import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import LoginLayout from '../../layouts/login';
import { useLocales } from '../../locales';
import AuthLoginForm from './AuthLoginForm';
// ----------------------------------------------------------------------
export default function Login() {
    const { translate } = useLocales();
    return (_jsxs(LoginLayout, { children: [_jsx(Stack, { spacing: 2, sx: { mb: 5, position: 'relative' }, children: _jsx(Typography, { variant: "h4", align: "center", children: `${translate('Sign in')}` }) }), _jsx(AuthLoginForm, {})] }));
}
